.foot_outer {
  position: absolute;
  .foot {
    background-color: black;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: relative;
    top: 100%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    .lk {
      color: white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    p {
      font-size: 14px;
      text-align: end;
      margin-top: 15px;
    }
  }
}
