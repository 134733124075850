.navbar_main {
  background-color: rgb(0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 7px;
  .links {
    .lk {
      color: white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .connect {
    display: flex;
    align-items: center;
    flex-direction: column;
    button {
      background-color: #161616;
      padding: 13px 25px;
      border: 1.5px solid #161616;
      transition: all 0.4s;
      &:hover {
        background-color: transparent;
        border: 1.5px solid #b2bec3;
      }
    }
  }
  .navbar-toggler {
    background: #20242c;
    .navbar-toggler-icon {
      filter: invert(100);
    }
    &:focus {
      box-shadow: none;
    }
  }
}
