* {
  font-family: "Rubik", sans-serif;
}
body {
  background-color: black;
  margin: 0px;
  padding: 0px;
}

video {
  z-index: -10;
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}
