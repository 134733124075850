.card {
  box-shadow: rgba(31, 31, 37, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  background-color: hsla(0, 0%, 50.2%, 0.208);
  color: rgb(255, 255, 255);
  border: 1.5px solid rgba(0, 0, 0, 0);
  border-radius: 10px;
  transition: all 0.2s;
  width: 100%;
  min-width: 300px;

  .card_hd {
    padding: 20px 25px;
    text-align: center;
    border-radius: 10px 10px 0px 0px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: rgba(128, 128, 128, 0.361);
  }
  .card_bd {
    text-align: center;
    padding: 20px 15px 0px 15px;
  }
  .pool_sz {
    padding: 0px 25px;
  }

  button {
    background-color: #161616;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 13px 40px;
    transition: all 0.4s;
    color: rgb(255, 255, 255);
    &:hover {
      background-color: transparent;
      border: 1.5px solid #ffffff;
      color: rgb(255, 255, 255);
    }
  }
}
