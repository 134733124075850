.stake_card_da_abo{
  min-height: 80vh;
  padding-top: 1.35%;
}
.stake_card {
  min-height: 30vw;
  max-width: 75vw;
  display: flex;
  flex-direction: column;
  align-content: center;
  z-index: 10;
  margin-top: 100px;
  margin-bottom: 100px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border-radius: 10px;
  background-color: hsla(0, 0%, 50.2%, 0.208);
  .pool {
    background-color: rgba(128, 128, 128, 0.361);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px 10px 0px 0px;
    padding: 25px 40px;
    p {
      font-size: 18px;
    }
    span {
      font-weight: bold;
    }
  }
  .crds_1 {
    .left-sideof-card {
      background-color: rgba(255, 255, 255, 0.037);
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      margin: 30px;
      padding: 20px;
      border-radius: 10px;
      color: white;
      button {
        margin-right: 15px;
        background-color: transparent;
        border: none;
        color: silver;
        font-weight: 600;
        padding: 0px;
        border: none;
      }
      .pOnEnd {
        text-align: end;
        color: white;
      }
      .inputMax {
        padding: 0px 10px;
      }
      .inputMax {
        margin-top: 10px;
        input {
          background-color: transparent;
          color: white;
        }
        padding: 0px 10px;
        display: flex;
        align-items: center;
        height: 55px;
        border: 1px solid rgb(255, 255, 255);
        box-sizing: border-box;
        border-radius: 10px;
        position: relative;
        input {
          width: 99%;
          font-weight: 600;
          font-size: 20px;
          // color: #000000;
          border: none;
          outline: none;
          padding-right: 55px;
          &::placeholder {
            font-weight: 600;
            font-size: 20px;
            // color: #000000;
          }
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        .mx {
          padding: 6px 15px;
          width: 71px;
          position: absolute;
          right: 10px;
          background-color: #161616;
          border: 1px solid #161616;
          color: rgb(255, 255, 255);
          border-radius: 7px;
          text-align: center;
          font-size: 17px;
          font-weight: 400;
          transition: all 0.3s;
          &:hover {
            background-color: transparent;
            border: 1px solid #b2bec3;
            color: white;
          }
        }
      }
      .buttons {
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        button {
          background-color: #161616;
          border: 2px solid #161616;
          padding: 10px 40px;
          transition: all 0.4s;
          margin: 10px 5px;
          border-radius: 7px;
          font-weight: 400;
          font-size: 20px;
          color: rgb(255, 255, 255);
          &:hover {
            background-color: transparent;
            border: 1.5px solid #b2bec3;
            color: white;
          }
        }
      }
    }

    .sum {
      background-color: rgba(255, 255, 255, 0.037);
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      margin: 30px;
      padding: 20px;
      border-radius: 10px;
      color: silver;
      table {
        td {
          padding: 15px;
        }
        color: rgb(255, 255, 255);
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .stake_card {
    max-width: 85vw;
  }
}
.stake_card .crds_1 .left-sideof-card button {
  margin-right: 15px;
  background-color: transparent;
  border: none;
  color: silver;
  font-weight: 600;
  padding: 0px;
  border: none;
  border-radius: 0px;
  padding-bottom: 7px;
  border-bottom: 3px solid transparent ;
}
.hoverTab{
  font-weight: 800 !important;
  border-bottom: 3px solid white !important;
  color: #fff !important;
  /* padding-bottom: 7px; */
}
.loaderdbtn{
  padding-left: 60px !important;
  padding-right: 60px !important;
}