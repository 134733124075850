.main_pg {
  position: relative;
  .ssss {
    height: 75vh;
  }
  video {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vh;
    object-fit: cover;
    opacity: 0.3;
    /* padding: 0rem 1.5rem !important; */
  }
}
